import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { InjectionToken, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxsModule } from '@ngxs/store';
// import { StoreModule } from '@ngrx/store';
import {
  ActionRequiredState,
  AppEnvironmentState,
  HeaderModule,
  PermissionsState,
  UserState,
  SharedModule,
  GeaTranslateHttpLoader,
  SidebarV2Module,
  IconModule,
  PageLayoutModule,
  FeatureFlagConfigurationState,
  PASSWORD_RESET_AUTH
} from '@gea/digital-ui-lib';
import {
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeactivateGuard } from './candeactivate-guard.service';
import { HrtLoaderComponent } from './shared/components/hrt-loader/hrt-loader.component';
import { HttpClient } from '@angular/common/http';
import { Environment } from 'src/environments/model';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

export function HttpLoaderFactory(http: HttpClient, environment: Environment) {
  return new GeaTranslateHttpLoader(http, environment.localizationStorageURL, ['i18n/1/support/']);
}

const ENVIRONMENT_CONFIG = new InjectionToken<Environment>('Environment_Configuration', { factory: () => environment});

@NgModule({
  declarations: [AppComponent, HrtLoaderComponent],
  imports: [
    NgxsModule.forRoot([UserState, ActionRequiredState, AppEnvironmentState, PermissionsState, FeatureFlagConfigurationState]),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HeaderModule.forRoot({
      redirectUri: environment.oAuth.redirectURL,
      postLogoutRedirectUri: environment.oAuth.postLogoutRedirectURL,
      clientId: environment.oAuth.clientId,
      authority: environment.oAuth.authority,
      authorityDomain: environment.oAuth.authorityDomain,
      portalURL: environment.portal.baseURL || environment.portal.redirectURL,
    }),
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'en-US',
      loader: {
        deps: [HttpClient, ENVIRONMENT_CONFIG],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    MsalModule,
    SharedModule,
    PageLayoutModule,
    SidebarV2Module,
    IconModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: 'baseUrl', useValue: environment.portal.redirectURL },
    { provide: 'subscriptionKey', useValue: '' },
    { provide: 'storageURL', useValue: environment.storageURL },
    { provide: 'iconPath', useValue: 'https://content.apps.tst.gea.com/strg-container-icons-test/'},
    {
      provide: PASSWORD_RESET_AUTH,
      useFactory: (env: Environment) => env.oAuth.passwordResetAuthority,
      deps: [ENVIRONMENT_CONFIG],
    },
    MsalService,
    ConfirmDeactivateGuard,
    MatDialog,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
