import { ApplicationFeature } from "./shared/data/application-feature";

export const sidebarNavigation = [
  {
    label: 'Home',
    route: '/home',
    icon: 'home',
    isInMenu: true,
    feature: ApplicationFeature.Home
  },
  {
    label: 'Dashboard',
    route: '/dashboard',
    icon: 'grid-interface',
    isInMenu: true,
    feature: ApplicationFeature.Dashboard
  },
  {
    label: 'Users',
    route: '/users',
    icon: 'multiple',
    isInMenu: true,
    feature: ApplicationFeature.Users
  },
  {
    label: 'User Editor',
    route: '/user-editor',
    icon: '16px_multiple',
    isInMenu: false,
    feature: ApplicationFeature.Users
  },
  {
    label: 'Companies',
    route: '/companies',
    icon: '16px_office',
    isInMenu: false,
    feature: ApplicationFeature.Companies
  },
  {
    label: 'Sites',
    route: '/sites',
    icon: 'factory',
    isInMenu: true,
    feature: ApplicationFeature.Sites
  },
  {
    label: 'Site Details',
    route: '/site-details',
    icon: '16px_factory',
    isInMenu: false,
    feature: ApplicationFeature.Sites
  },
  {
    label: 'Assets',
    route: '/assets',
    icon: 'playlist',
    isInMenu: true,
    feature: ApplicationFeature.Assets
  },
  {
    label: 'Assets Details',
    route: '/asset-details',
    icon: '16px_playlist',
    isInMenu: false,
    feature: ApplicationFeature.Assets
  },
  {
    label: 'Service Record',
    route: 'asset-details/service-record',
    isInMenu: false,
    feature: ApplicationFeature.Assets
  },
  {
    label: 'Order Status',
    route: '/order-status',
    icon: 'progress',
    isInMenu: true,
    feature: ApplicationFeature.OrderStatus
  },
  {
    label: 'Master Schedule',
    route: '/master-schedule',
    icon: '16px_progress',
    isInMenu: false,
    feature: ApplicationFeature.OrderStatus
  },
  {
    label: 'Products',
    route: '/add-products',
    icon: 'add-notification',
    // icon: '16px_warning-sign',
    isInMenu: true,
    feature: ApplicationFeature.Products
  },
  {
    label: 'Tools',
    route: '/add-products;isTools=true',
    icon: 'measurement',
    isInMenu: true,
    feature: ApplicationFeature.Tools,
    params: { isTools: true }
  },
  {
    label: 'Parts',
    route: '/parts-list',
    icon: 'wrench-tool',
    // icon: '16px_warning-sign',
    isInMenu: true,
    feature: ApplicationFeature.Parts
  },
  {
    label: 'Document Library',
    route: '/document-library',
    icon: 'book',
    // icon: '16px_warning-sign',
    isInMenu: true,
    feature: ApplicationFeature.Documents
  },
  {
    label: 'Document Details',
    route: '/document-details',
    icon: '16px_book',
    // icon: '16px_warning-sign',
    isInMenu: false,
    feature: ApplicationFeature.Documents
  },
  {
    label: 'Bookmarks',
    route: '/bookmarks',
    icon: 'bookmark',
    // icon: '16px_warning-sign',
    isInMenu: true,
    feature: ApplicationFeature.Home
  },
  {
    label: 'Feedback',
    route: '/feedback',
    icon: 'chat',
    // icon: '16px_warning-sign',
    isInMenu: true,
    feature: ApplicationFeature.Feedback
  },
  {
    label: 'Feedback Details',
    route: '/feedback-details',
    icon: '16px_chat',
    // icon: '16px_warning-sign',
    isInMenu: false,
    feature: ApplicationFeature.Feedback
  },
  {
    label: 'Sandbox',
    route: '/sandbox',
    icon: 'archive',
    // icon: '16px_warning-sign',
    isInMenu: true,
    feature: ApplicationFeature.AdminPage
  },
  {
    label: 'Config',
    route: '/config',
    icon: 'settings-gear',
    // icon: '16px_warning-sign',
    isInMenu: true,
    feature: ApplicationFeature.AdminPage
  },
  {
    label: 'Dakota City Site',
    route: '/site-details;id=2012',
    icon: 'pin-3',
    // icon: '16px_warning-sign',
    isInMenu: true,
    feature: ApplicationFeature.Assets
  }
];
