import { Component, Input } from '@angular/core';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { tap } from 'rxjs';
import { GeoChartOptions } from 'src/app/shared/components/geo-chart/geo-chart.component';
import { Site } from 'src/app/shared/data/data.generated';
import { HRTColumnDefinition, SelectedFilters } from 'src/app/shared/data/filterConfigurationTypes';
import { GetCustomTypeText } from 'src/app/shared/data/product-types';
import { tableDefinitions } from 'src/app/shared/data/table-definitions';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  Table,
  TableResult,
  TableView,
} from 'src/app/shared/services/api/table';
import { DashboardQueryType, DashboardService, DashboardServiceCondition } from 'src/app/shared/services/dashboard.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { RegionDataService } from 'src/app/shared/services/region-data.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-dashboard-sites',
  templateUrl: './dashboard-sites.component.html',
  styleUrls: ['./dashboard-sites.component.scss']
})
export class DashboardSitesComponent {

  tableId: string = 'dashboardSites';
  totalCount: number = 0;
  columnDefinitions: HRTColumnDefinition[];
  tableData: any[];
  listOptions: TableView = new TableView();
  siteTable: Table<Site> = new Table<Site>(this.loader);

  countries: any[] = [];
  presetFilter: SelectedFilters = {
    'Country': [{
      key: 'USA',
      label: 'United States'
    }]
  };
  defaultTableConfig: FilterTableSettings = {
    pageSize: 20,
    columns: {}
  }
  assetsType: DashboardQueryType = DashboardQueryType.Sites;
  selectedCountry: string = 'USA';
  selectedState: string = '';
  selectedType: DashboardQueryType = DashboardQueryType.Sites;
  selectedCondition: DashboardServiceCondition = DashboardServiceCondition.All;
  selectedProductType: number = -1;
  appliedType: DashboardQueryType = DashboardQueryType.Sites;

  showWorldMap: boolean = true;
  showStateMap: boolean = true;
  tableDataLoading: boolean = false;
  initialLoad: boolean = true;

  mapData: (string | number)[][] = [];
  mapDataLoading: boolean = false;
  worldOptions: GeoChartOptions = GeoChartOptions.WorldViewOptions;
  stateOptions: GeoChartOptions = GeoChartOptions.StateViewOptions;

  getCustomTypeText = GetCustomTypeText;

  public displayedColumns: string[] = [
    'SiteName',
    'SiteAddress',
    'City',
    'State',
    'SiteZip',
    'SiteAccountNumbers',
    'SiteProjectNumbers',
  ];

  constructor(
    private regionData: RegionDataService,
    private api: ApiService,
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
    private loader: LoaderService
  ) {}

  @Input() data: any;

  ngOnInit(): void {
    this.applyPresetFilters();
    if (sessionStorage.getItem(this.tableId + '-listOptions')) {
      this.listOptions = JSON.parse(
        sessionStorage.getItem(this.tableId + '-listOptions') || ''
      );
    }

    this.siteTable.GetApi = (listOptions: TableView) => {
      return this.api.Sites.GetSites(
        listOptions,
        this.selectedCountry,
        this.selectedState,
        this.selectedProductType
      ).pipe(
        tap((siteData: TableResult<Site>) => {
          this.tableData = siteData.Data;
          this.totalCount = siteData.Count;
        })
      );
    };

    this.siteTable.Updated = () => (this.tableDataLoading = false);

    // For initial load, control doesn't go in tap unless subscribed.
    // this.siteTable.refreshDataForTable()?.subscribe();

    // this.siteTable.Updated = () => (this.tableDataLoading = false);
    this.assignMockColDefinitions();
    this.updateMapAndTable();
    this.utilityService.setActiveTab(1, 'dashboardActiveIndex');
  }

  ngAfterViewInit() {
    this.initialLoad = false;
  }

  assignMockColDefinitions() {
    this.columnDefinitions = tableDefinitions.siteTableDef;
  }

  applyPresetFilters() {
    if (!sessionStorage.getItem(this.tableId)) return;
    this.presetFilter = JSON.parse(
      sessionStorage.getItem(this.tableId) || ''
    );

    this.selectedCountry = (this.presetFilter['Country'] && this.presetFilter['Country'].length > 0) ? this.presetFilter['Country'][0].key.toString() : '';
    this.selectedState = (this.presetFilter['State'] && this.presetFilter['State'].length > 0) ? this.presetFilter['State'][0].key.toString() : '';
  }


  handleSelectedFilters(selectedFilters: SelectedFilters) {
    // console.log('Selected filters in parent:', selectedFilters);
    if (selectedFilters) {
      if (!selectedFilters['Country']) {
        this.selectedCountry = '';
        this.selectedState = '';
      }
      if (!selectedFilters['State']) {
        this.selectedState = '';
      }
      this.updateMapAndTable();
    }
  }

  regionSelected(region: string) {
    if (this.showWorldMap) {
      let lookup2Digit = this.regionData.Countries.find(
        (x) => x.Code2 == region
      );
      let lookupName = this.regionData.Countries.find((x) => x.Name == region);
      lookup2Digit?.Name ? this.updateCountryFilter(lookup2Digit.Code3, lookup2Digit.Name) : null;
      this.selectedCountry = lookup2Digit?.Code3 ?? lookupName?.Code3 ?? region;
      this.updateMapAndTable();
    } else if (this.showStateMap) {
      const stateName = this.regionData.States.find(x => x.Code == region)?.Name;
      this.selectedState = region;
      if (stateName)
        this.updateStateFilter(region, stateName);
      this.updateMapAndTable();
    }
  }

  updateCountryFilter(countryCode: string, countryName: string) {
    if (this.presetFilter) {
      this.presetFilter = {
        ...this.presetFilter,
        'Country': [
          { 
            key: countryCode,
            label: countryName
          }
        ]
      };
    }
    // this.countryFilter['Country'][0].label = countryName;
  }

  updateStateFilter(stateCode: string, stateName: string) {
    if (this.presetFilter) {
      this.presetFilter = {
        ...this.presetFilter,
        State: [
          { 
            key: stateCode,
            label: stateName
          }
        ]
      };
    }
  }

  updateMapAndTable() {
    //this.tableDataLoading = true;
    this.stateOptions.colorAxis = { colors: ['lightGray', '#0303B8'] };

    if (this.selectedType !== DashboardQueryType.OperatingData) {
      this.appliedType = this.selectedType;
    }
    if (this.selectedCountry === '' && this.selectedState === '') {
      this.showWorldMap = true;
      this.showStateMap = false;
    } else if (this.selectedCountry === 'USA') {
      //this.selectedState === ""
      this.showWorldMap = false;
      this.showStateMap = true;
    } else {
      this.showWorldMap = false;
      this.showStateMap = false;
    }
    if (this.showWorldMap || this.showStateMap) {
      this.mapDataLoading = true;
      this.siteTable.oldRequest = {};
      this.siteTable.SearchUpdated();
      this.api.Assets.GetAssetMapData(
        this.selectedType,
        this.selectedCountry,
        this.selectedProductType,
        this.selectedCondition
      ).subscribe((x) => {
        this.mapData = this.dashboardService.normalizeCountryData(x);
        this.mapDataLoading = false;
      });
    } else {
      // To fetch data for countrties other than US.
      // regionSelected -> updateCountryFilter -> here (with updated selectedCountry).
      // since no filters are passed for updated country or city.
      this.siteTable.oldRequest = {};
      this.siteTable.SearchUpdated();
    }
  }


  rowClicked(rowData: any) {
    // console.log('Row click event', rowData);
    this.utilityService.openSite(rowData.Id);
  }

  exportSites() {
    const listOptions = this.listOptions;
    listOptions.Page = 0;
    listOptions.HasPaging = false;
    this.api.Sites.ExportSites(listOptions,
      this.selectedCountry,
      this.selectedState,
      this.selectedProductType);
  }

  backToWorld() {
    this.selectedCountry = '';
    this.selectedState = '';
    this.presetFilter = {
      ...this.presetFilter,
      Country : [],
      State: []
    };
    this.updateMapAndTable();
  }


  ngOnDestroy() {
    sessionStorage.setItem(
      this.tableId + '-listOptions',
      JSON.stringify(this.listOptions)
    );
  }
}

