import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TableView } from '../../services/api/table';
import {
  AppliedFilters,
  AutoCompleteFilterOptions,
  Dropdown,
  FilterOptions,
  FilterRenderConfiguration,
  GetFilterConfigKey,
  GetFilterConfigName,
  GetFilterConfigType,
  SelectedFilters,
} from '../../data/filterConfigurationTypes';
import { BehaviorSubject, debounceTime, filter, of, switchMap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { UtilityService } from '../../services/utility.service';
import { FilterConfig, SubFilterConfig } from '../../data/filterConfigurations';

@Component({
  selector: 'hrt-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrl: './filter-search.component.scss',
})
export class FilterSearchComponent {
  @Input() set tableId(id: string) {
    this._tableId = id;
  }
  get tableId(): string {
    return this._tableId;
  }
  @Input() pageName: string; //page name needed for autocomplete.
  @Input() searchModelField: TableView;
  @Input() hasFilter: boolean = true;
  @Input() searchTextPlaceholder: string;
  @Input() set presetFilters(presetFilters: SelectedFilters) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...(presetFilters || {}),
    };
    this.applyFilter();
  }

  @Output() searchVal = new EventEmitter<string>();
  @Output() filters = new EventEmitter<any>();

  _tableId: string;
  inputSubject = new BehaviorSubject<string>('');
  autocompleteSubject = new BehaviorSubject<string>('');
  filtersHeight: any;
  autoFieldsToPrefetch: string[] = [];
  currentAutoFieldKey: string;
  currentAutoFieldConfig: string;
  autoselectFC = new FormControl();
  autocompleteOptions: SelectedFilters = {};
  isOptionSelected = false;
  showFilterSelection: boolean = false;
  filterRenderConfigurations: FilterRenderConfiguration[] = [];
  selectedFilters: SelectedFilters = {}; // main binding filter obj.
  matchedTerms: number = 0;
  applyDisabled: boolean = false;
  filtersChips: AppliedFilters[] = []; // for chips.
  fieldInvalid: any = {};

  private resizeObserver: ResizeObserver;

  public getFilterConfigKey = GetFilterConfigKey;
  public getFilterConfigName = GetFilterConfigName;

  @ViewChild('filterSelection') filtersDiv: ElementRef;

  constructor(
    private api: ApiService,
    private utilityService: UtilityService,
    private render: Renderer2
  ) {}

  ngOnInit() {
    const sessionStoredFilters = sessionStorage.getItem(this.tableId);
    if (sessionStoredFilters) {
      this.selectedFilters = {
        ...(sessionStoredFilters ? JSON.parse(sessionStoredFilters) : {}),
      };
      this.applyFilter();
    }
    // If previously searched, retrieve from session storage.
    if (sessionStorage.getItem(this.tableId + '-searchText')) {
      this.searchModelField.Search =
        sessionStorage.getItem(this.tableId + '-searchText') || '';
      this.inputSubject.next(this.searchModelField.Search);
    }
    // For change in the input field.
    this.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      if (value) {
        sessionStorage.setItem(this.tableId + '-searchText', value);
      } else if (
        value == '' &&
        sessionStorage.getItem(this.tableId + '-searchText')
      ) {
        sessionStorage.removeItem(this.tableId + '-searchText');
      }
      this.searchVal.emit(value);
    });
    this.autoselectFC.valueChanges
      .pipe(
        debounceTime(500),
        filter((value) => value != undefined && value != null),
        switchMap((value) => {
          if (this.isOptionSelected) {
            this.isOptionSelected = false;
            return of([]);
          }

          if (this.autocompleteOptions[this.currentAutoFieldConfig]) {
            // If autocompleteOptions[this.currentAutoField] already exists, sort the list
            // else make API call.
            return of(
              this.sortList(
                value,
                this.autocompleteOptions[this.currentAutoFieldConfig]
              )
            );
          } else {
            return this.api.Filters.GetAutocompleteList(
              value,
              this.currentAutoFieldKey,
              this.pageName
            );
          }
        })
      )
      .subscribe((result) => {
        if (!this.autocompleteOptions[this.currentAutoFieldConfig]) {
          this.autocompleteOptions[this.currentAutoFieldConfig] =
            this.convertToDropdownFormat(result);
        } else {
          this.autocompleteOptions[this.currentAutoFieldConfig] = result;
        }
      });
    this.getFilters();
  }

  ngAfterViewInit() {

    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.adjustFiltersHeight(entry.contentRect.height);
      }
    });
    // Observe changes to filter selection height.
    this.resizeObserver.observe(this.filtersDiv.nativeElement);

    // autoFieldsToPrefetch has the configNames.
    if (this.autoFieldsToPrefetch.length > 0) {
      const fieldKeysToFetch = this.autoFieldsToPrefetch.map(config => { return GetFilterConfigKey(config) }).join(',')
      this.api.Filters.GetMultipleAutoLists('', fieldKeysToFetch, this.pageName).subscribe((res) => {
        this.autoFieldsToPrefetch.map(config => {
          const key = GetFilterConfigKey(config);
          this.autocompleteOptions[config] = this.convertToDropdownFormat(res[key]);
        })
      });
    }
    // console.log("Height:", height);
  }

  adjustFiltersHeight(height: number) {
    this.filtersHeight = height || 0;
  }

  /**
   * Get the filters list from the configuration file.
   * @returns 
   */
  getFilters() {
    let userType = sessionStorage.getItem('RoleId');
    let isInternal = userType == '6000' || userType == '7000';
    if (!SubFilterConfig[this.tableId]) return;
    SubFilterConfig[this.tableId].map((col: string) => {
      if (
        FilterConfig[col] &&
        (!FilterConfig[col].internalOnly ||
          (FilterConfig[col].internalOnly && isInternal))
      ) {
        const obj = {
          configName: col,
          ...FilterConfig[col],
        };
        this.filterRenderConfigurations.push(obj);
        if ((obj as AutoCompleteFilterOptions).prefetch) {
          this.autoFieldsToPrefetch.push(obj.configName);
        }
      }
    });
  }


  /**
   * Sort list based on searchTerm,
   * To show the matching terms on the top.
   * @param searchText
   * @param list
   * @returns
   */
  sortList(searchText: string, list: Dropdown[]): Dropdown[] {
    searchText = searchText.toLowerCase();
    this.matchedTerms = list.filter((item: Dropdown) =>
      item.label.toLowerCase().includes(searchText)
    ).length;
    list.sort((a: Dropdown, b: Dropdown) => {
      if (searchText == '') {
        this.matchedTerms = 0;
        // sort alphabetically.
        return a.label.localeCompare(b.label);
      } else if (
        a.label.toLowerCase().includes(searchText) &&
        !b.label.toLowerCase().includes(searchText)
      ) {
        return -1;
      } else if (
        !a.label.toLowerCase().includes(searchText) &&
        b.label.toLowerCase().includes(searchText)
      ) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }

  /**
   * Convert to string[] to interface Dropdown[]
   * @param dropdownList
   * @returns
   */
  convertToDropdownFormat(dropdownList: string[]) {
    if (!dropdownList || dropdownList.length < 0) return [];

    const convertedDropdown: Dropdown[] = [];
    dropdownList.map((dropdown) => {
      const option: Dropdown = {
        key: dropdown,
        label: dropdown,
      };
      convertedDropdown.push(option);
    });
    return convertedDropdown;
  }

  searchChange(searchField: string) {
    // this.inputSubject.next(searchField);
    this.inputSubject.next(this.searchModelField.Search);
  }

  /**
   * Makes a call to get the entire dropdown on click of the field.
   * @param filterConfig
   */
  autoFieldClicked(
    filterConfig: string,
    filterKey: string,
    filterType: FilterOptions['type']
  ) {
    this.currentAutoFieldConfig = filterConfig;
    this.currentAutoFieldKey = filterKey;
    // Changes value from null to '' to trigger change.
    this.autoselectFC.setValue('');
  }

  /**
   * Assign value when autocomplete option is selected.
   * @param option
   * @param configName
   * @param event
   */
  autoOptionClicked(option: Dropdown, configName: string, event: Event) {
    this.isOptionSelected = true;
    if (!this.selectedFilters[configName]) {
      this.selectedFilters[configName] = [
        {
          key: option.key,
          label: option.label,
        },
      ];
    } else {
      this.selectedFilters[configName].push({
        key: option.key,
        label: option.label,
      });
    }
  }

  /**
   * Set selectedFilters object.
   * @param configName
   * @param filterKey
   * @param filterEvent
   * @param filterType
   */
  addToFilterList(
    configName: string,
    filterKey: string,
    filterEvent: any,
    filterType: FilterOptions['type']
  ) {
    if (filterType == 'multiSelect' || filterType == 'autoMultiSelect') {
      this.selectedFilters[configName] = filterEvent.value;
    } else if (filterType == 'select' || filterType == 'autoSelect') {
      this.selectedFilters[configName] = [filterEvent.value];
    }
  }

  /**
   * Clears matched terms and resorts the list.
   * @param configName
   * @returns
   */
  onPanelClose(configName: string) {
    if (this.matchedTerms == 0) return;
    // to reset the list.
    this.matchedTerms = 0;
    this.sortList('', this.autocompleteOptions[configName]);
  }

  /**
   * To handle date filters.
   * @param filterConfig
   * @param filterKey
   * @param dateRangeStart
   * @param dateRangeEnd
   * @returns
   */
  dateRangeChanged(
    filterConfig: string,
    filterKey: string,
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement
  ) {
    if (
      !this.utilityService.isDateRangeValid(
        dateRangeStart.value,
        dateRangeEnd.value
      )
    ) {
      this.fieldInvalid[filterConfig] = true;
      return;
    }
    this.fieldInvalid[filterConfig] = undefined;
    this.selectedFilters[filterConfig] = [
      {
        key: new Date(dateRangeStart.value).toISOString(),
        label: dateRangeStart.value,
      },
      {
        key: new Date(dateRangeEnd.value).toISOString(),
        label: dateRangeEnd.value,
      },
    ];
  }

  comparer(o1: Dropdown, o2: Dropdown): boolean {
    return o1 && o2 ? o1.label === o2.label : o1 === o2;
  }

  /**
   * Called on apply button click to apply the filters.
   * @returns
   */
  applyFilter() {
    if (!this.selectedFilters) return;

    this.updateFilterList();
    this.showFilterSelection = false;
    this.filters.emit(this.selectedFilters);
    sessionStorage.setItem(this.tableId, JSON.stringify(this.selectedFilters));
  }

  /**
   * Function to display the filters as chips.
   */
  updateFilterList() {
    this.filtersChips = [];
    Object.keys(this.selectedFilters).map((configName) => {
      const filterType = GetFilterConfigType(configName);
      const values = this.selectedFilters[configName];
      if (values.length > 0) {
        if (filterType == 'dateRange') {
          this.filtersChips.push({
            configName: configName,
            key: this.getFilterConfigKey(configName),
            name: this.getFilterConfigName(configName),
            value: `${this.utilityService.formatDate(
              values[0].key.toString()
            )} - ${this.utilityService.formatDate(values[1].key.toString())}`,
          });
        } else {
          values.map((value: any) => {
            this.filtersChips.push({
              configName: configName,
              key: this.getFilterConfigKey(configName),
              name: this.getFilterConfigName(configName),
              value: value.label,
            });
          });
        }
      }
    });
  }

  /**
   * Removes the filter from chips and selectedFilters.
   * @param filterObj
   * @returns
   */
  removeFilter(filterObj: any) {
    // Function to remove each filter.
    if (!this.selectedFilters[filterObj.configName]) return;

    const filterType = GetFilterConfigType(filterObj.configName);

    if (filterType != 'dateRange') {
      if (Array.isArray(this.selectedFilters[filterObj.configName])) {
        this.selectedFilters[filterObj.configName] = this.selectedFilters[
          filterObj.configName
        ].filter((x: any) => x.label !== filterObj.value);
        this.removeUnappliedFilters();
      } else {
        delete this.selectedFilters[filterObj.configName];
      }
    } else {
      // it is dateRange.
      delete this.selectedFilters[filterObj.configName];
    }
    this.updateFilterList();
    this.showFilterSelection = false;
    sessionStorage.setItem(this.tableId, JSON.stringify(this.selectedFilters));
    this.filters.emit(this.selectedFilters);
  }

  /**
   * Remove filters which are selected but not applied during remove.
   * @returns
   */
  removeUnappliedFilters() {
    if (!this.selectedFilters) return;
    Object.keys(this.selectedFilters).map((configName: string) => {
      const appliedValues = this.filtersChips
        .filter((filter) => filter.configName == configName)
        .map((filter) => filter.value);
      // To-DO: Comparision if lengths are different.
      this.selectedFilters[configName] = this.selectedFilters[
        configName
      ].filter((selectedFilter) =>
        appliedValues.includes(selectedFilter.label)
      );
      if (this.selectedFilters[configName].length == 0) {
        delete this.selectedFilters[configName];
      }
    });
  }

  /**
   * Clear all filters functionality.
   */
  resetAllFilters() {
    this.filtersChips = [];
    this.selectedFilters = {};
    this.showFilterSelection = false;
    sessionStorage.removeItem(this.tableId);
    this.filters.emit([]);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.filtersDiv.nativeElement);
  }
}
