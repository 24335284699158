import {
  Component,
  NgModule,
  Input,
  ViewChild,
  QueryList,
  ViewChildren,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  ButtonV2Module,
  FilterTableSettings,
  IconModule,
  TableServiceV2,
  TableV2Module,
  FilterTableSettingsCache
} from '@gea/digital-ui-lib';
import { MaterialModule } from 'src/app/material.module';
import {
  Dropdown,
  GetFilterConfigName,
  HRTColumnDefinition,
  SelectedFilters,
} from '../../data/filterConfigurationTypes';
import { MatSelect } from '@angular/material/select';
import { debounceTime, Subscription, BehaviorSubject } from 'rxjs';
import { DropdownButtonComponent } from '../dropdown-button/dropdown-button.component';
import { FilterConfig } from '../../data/filterConfigurations';

@Component({
  selector: 'hrt-table',
  templateUrl: './hrt-table.component.html',
  styleUrls: ['./hrt-table.component.scss'],
})
export class HrtTableComponent {

  @Input() tableId: string;
  @Input() columnDefinitions: HRTColumnDefinition[];
  @Input() defaultTableSettings: FilterTableSettings;
  @Input() tableData: any;
  @Input() totalCount: number;
  @Input() isLoading: boolean = true;
  @Input() hasFilter: boolean;
  @Input() searchTextPlaceholder: string = 'Search';
  @Input() hasExport: boolean = false;

  @Input() set presetFilters(presetFilters: SelectedFilters) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...(presetFilters || {})
    };
    this.applyFilter();
  };

  @Output() tableSettings = new EventEmitter<FilterTableSettings>();
  @Output() searchText = new EventEmitter<string>();
  @Output() filters = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<string>();
  @Output() exportClicked = new EventEmitter();

  @ViewChildren('matRef') matRefs: QueryList<MatSelect>;

  searchValue: string = '';
  showFilterSelection: boolean = false;
  applyDisabled: boolean = false;
  selectedFilters: SelectedFilters = {}; // object to be sent back to the parent.
  filtersApplied: any[] = []; // to show as chips.
  filterConfigurations: any[] = []; // to show the filter for selection

  inputSubject = new BehaviorSubject<string>('');
  subscriptions: Subscription[] = [];

  public getFilterGetFilterConfigName = GetFilterConfigName;

  constructor(private tableService: TableServiceV2) {}

  ngOnInit() {
    const sessionStoredFilters = sessionStorage.getItem(this.tableId);
    if (sessionStoredFilters) {
      this.selectedFilters = {
        ...(sessionStoredFilters ? JSON.parse(sessionStoredFilters) : {})
      };
      this.applyFilter();
    }
    // If previously searched, retrieve from session storage.
    if (sessionStorage.getItem(this.tableId + '-searchText')) {
      this.searchValue = sessionStorage.getItem(this.tableId + '-searchText') || '';
      this.searchText.emit(this.searchValue);
    }
    // For change in the input field.
    this.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      if (value) {
        sessionStorage.setItem(this.tableId + '-searchText', value);
      } else if (value == '' && sessionStorage.getItem(this.tableId + '-searchText')) {
        sessionStorage.removeItem(this.tableId + '-searchText');
      }
      this.searchText.emit(value);
    });
    this.subscriptions.push(
      this.tableService
        .getFilterTableSettings(this.tableId)
        .subscribe((columnSettings: FilterTableSettings) => {
          this.tableSettings.emit(columnSettings);
          // console.log('column settings received for table ID:', columnSettings, this.tableId);
        })
    );
    this.getFilters(this.columnDefinitions);
  }

  searchChange(searchText: string) {
    if (searchText.trim() !== this.inputSubject.getValue()) {
      this.inputSubject.next(searchText);
    }
  }

  comparer(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.label === o2.label : o1 === o2;
  }

  getFilters(columnDefinitions: any) {
    columnDefinitions.map((col: any) => {
      if (!col.hasFilter) return;
      if (FilterConfig[col.key]) {
        const obj = {
          ...FilterConfig[col.key],
        };
        this.filterConfigurations.push(obj);
      }
    });
  }

  addToFilterList(filterName: string, filterEvent: any, filterType: 'select' | 'multiSelect') {
    if (filterType == 'multiSelect') {
      this.selectedFilters[filterName] = filterEvent.value;
    } else {
      this.selectedFilters[filterName] = [filterEvent.value]
    }
  }

  updateFilterList() {
    this.filtersApplied = [];
    Object.keys(this.selectedFilters).map((filter) => {
      const values = this.selectedFilters[filter];
      if (values.length > 0) {
        values.map((value: any) => {
          this.filtersApplied.push({
            key: filter,
            name: this.getFilterGetFilterConfigName(filter),
            value: value.label,
          });
        });
      }
    });
  }

  removeFilter(filterObj: any) {
    // Function to remove each filter.
    if (!this.selectedFilters[filterObj.key]) return;

    if (Array.isArray(this.selectedFilters[filterObj.key])) {
      this.selectedFilters[filterObj.key] = this.selectedFilters[
        filterObj.key
      ].filter((x: any) => x.label != filterObj.value);
      if (this.selectedFilters[filterObj.key].length == 0) {
        delete this.selectedFilters[filterObj.key];
      }
    } else {
      delete this.selectedFilters[filterObj.key];
    }
    this.updateFilterList();
    sessionStorage.setItem(this.tableId, JSON.stringify(this.selectedFilters));
    this.filters.emit(this.selectedFilters);
  }


  applyFilter() {
    if (!this.selectedFilters) return;

    this.updateFilterList();
    this.showFilterSelection = false;
    // document
    //   .querySelectorAll('.mat-select-min-line')
    //   .forEach((ele: Element) => {
    //     ele.innerHTML = '';
    //   });
    this.filters.emit(this.selectedFilters);
    sessionStorage.setItem(this.tableId, JSON.stringify(this.selectedFilters));
  }

  resetAllFilters() {
    this.filtersApplied = [];
    this.selectedFilters = {};
    sessionStorage.removeItem(this.tableId);
    this.filters.emit([]);
  }

  getTableRowData(rowData: any) {
    this.rowClicked.emit(rowData);
  }

  exportTable() {
    this.exportClicked.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    TableV2Module,
    ButtonV2Module,
    IconModule
  ],
  declarations: [HrtTableComponent, DropdownButtonComponent],
  exports: [HrtTableComponent],
})
export class HrtTableModule {}
