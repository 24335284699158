import { Component, Input, OnInit } from '@angular/core';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { tap } from 'rxjs';
import { TableIconComponent } from 'src/app/shared/components/table-icon/table-icon.component';
import { Asset, Site } from 'src/app/shared/data/data.generated';
import { HRTColumnDefinition } from 'src/app/shared/data/filterConfigurationTypes';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
  GetCustomTypeText,
} from 'src/app/shared/data/product-types';
import { tableDefinitions } from 'src/app/shared/data/table-definitions';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  Table,
  TableResult,
  TableView,
} from 'src/app/shared/services/api/table';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { HrtTableService } from 'src/app/shared/services/hrt-table.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-assets-screw',
  templateUrl: './asset-screw.component.html',
  styleUrls: ['./asset-screw.component.scss'],
})
export class AssetScrewComponent {
  public id: number = -1;
  public table: Table<Asset> = new Table<Asset>();
  public getCustomTypeText = GetCustomTypeText;
  public getCustomTypeIcon = GetCustomTypeIcon;
  public getCustomTypeIconWidth = GetCustomTypeIconWidth;

  tableId: string = 'assetScrewComponent';
  totalCount: number = 0;
  columnDefinitions: HRTColumnDefinition[];
  defaultTableConfig: FilterTableSettings = {
    pageSize: 20,
    columns: {},
  };
  tableData: any;
  listOptions: TableView = new TableView();
  assetTable: Table<Asset> = new Table<Asset>(this.loader);
  selectedProductType: number = 101;

  tableDataLoading: boolean = false;
  initialLoad: boolean = true;

  mapData: (string | number)[][] = [];
  mapDataLoading: boolean = false;

  constructor(
    private api: ApiService,
    private dashboardService: DashboardService,
    private hrtTableService: HrtTableService,
    private utilityService: UtilityService,
    private loader: LoaderService
  ) {}

  @Input() data: any;

  ngOnInit(): void {
    if (sessionStorage.getItem(this.tableId + '-listOptions')) {
      this.listOptions = JSON.parse(
        sessionStorage.getItem(this.tableId + '-listOptions') || ''
      );
    }
    this.assetTable.GetApi = (listOptions: TableView) => {
      return this.api.Assets.GetAssets(
        this.listOptions,
        undefined,
        undefined,
        undefined,
        this.selectedProductType
      ).pipe(
        tap((assetData: TableResult<Asset>) => {
          this.tableData = this.modifyTableData(assetData.Data);
          this.totalCount = assetData.Count;
        })
      );
    };

    this.assetTable.Updated = () => (this.tableDataLoading = false);

    // For initial load, control doesn't go in tap unless subscribed.
    this.assetTable.refreshDataForTable()?.subscribe();

    // this.siteTable.Updated = () => (this.tableDataLoading = false);
    this.assignMockColDefinitions();
  }

  ngAfterViewInit() {
    this.initialLoad = false;
  }

  assignMockColDefinitions() {
    let definitions = tableDefinitions.assetTableDef;

    this.columnDefinitions = this.hrtTableService.addTypeIconToColumn(
      definitions,
      ['ProductTypeId'],
      TableIconComponent
    );
  }

  modifyTableData(assetData: any) {
    const modifiedAsset = assetData.map((asset: any) => {
      return {
        ...asset,
        ProductTypeIdText: this.getCustomTypeText(asset.ProductTypeId),
      };
    });
    return modifiedAsset;
  }

  handleTableSettings(tableSettings: FilterTableSettings) {
    if (this.initialLoad) return;

    const oldOptions = JSON.parse(JSON.stringify(this.listOptions));

    const tableColKeys: string[] = Object.keys(tableSettings.columns);
    let sortKey = '';
    let sortDes = 'False';
    if (tableColKeys.length > 0) {
      // sorting exists
      for (let key in tableSettings.columns) {
        let sortValue = tableSettings.columns[key].sort;
        if (sortValue === 'asc' || sortValue === 'desc') {
          sortKey = key;
          sortDes = sortValue === 'desc' ? 'True' : 'False';
          break;
        }
      }
      this.assetTable.View.SortColumn = sortKey;
      this.assetTable.View.SortDesc = sortDes;

      this.listOptions.SortColumn = sortKey;
      this.listOptions.SortDesc = sortDes;
    }
    this.assetTable.View.Page = tableSettings.page;
    this.listOptions.Page = tableSettings.page;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);

    // Object comparision as tableSettings sends multiple events.
    if (this.utilityService.deepEqual(oldOptions, this.listOptions)) return;
    this.assetTable.SearchUpdated();
  }

  updateSearchTerm(searchText: string) {
    if (this.initialLoad) return;
    this.assetTable.View.Search = searchText;
    this.listOptions.Search = searchText;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
    this.assetTable.SearchUpdated();
  }

  rowClicked(rowData: any) {
    this.utilityService.openAsset(rowData.Id);
  }

  ngOnDestroy() {
    sessionStorage.setItem(
      this.tableId + '-listOptions',
      JSON.stringify(this.listOptions)
    );
  }
}
