<div mat-dialog-title class="description">{{action}} Configuration</div>
<div mat-dialog-content class="add-config-container">
  <form
    #configForm="ngForm"
    name="configForm"
    class="config-form"
  >
    <div *ngIf="data.flag === 'ConfigList'">
      <div class="hrt-form-field">
        <label>Config Name:</label>
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="newConfig.ConfigName"
            name="configName"
            #configName="ngModel"
            required
          />
          <mat-hint
            *ngIf="configName.invalid && configName.touched"
            style="font-size: 10px; color: red"
            >Config Name is required.</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="hrt-form-field">
        <label>Config Value:</label>
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="newConfig.ConfigValue"
            name="configValue"
            #configValue="ngModel"
            required
          />
          <mat-error *ngIf="configValue.invalid && configValue.touched"
            >Config Value is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="hrt-form-field">
        <label>Is UI Config?</label>
        <mat-form-field>
          <mat-select
            [(ngModel)]="newConfig.isUIConfig"
            name="isUIConfig"
            #isUIConfig="ngModel"
            placeholder="Select Type"
            required
          >
            <mat-option
              *ngFor="let item of isUIConfigDropdown"
              [value]="item.key"
            >
              {{ item.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>


    <!-- Config Data Section -->

    <div *ngIf="data.flag === 'ConfigData'" >

      <div class="hrt-form-field">
        <label>Target:</label>
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="newConfigData.Target"
            name="target"
            #target="ngModel"
            required
          />
          <mat-hint
            *ngIf="target.invalid && target.touched"
            style="font-size: 10px; color: red"
            >Target is required.</mat-hint
          >
        </mat-form-field>
      </div>
  
      <div class="hrt-form-field">
        <label>Key:</label>
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="newConfigData.key"
            name="key"
            #configKey="ngModel"
            required
          />
          <mat-hint
            *ngIf="configKey.invalid && configKey.touched"
            style="font-size: 10px; color: red"
            >Key is required.</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="hrt-form-field">
        <label>Value:</label>
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="newConfigData.value"
            name="value"
            #configValue="ngModel"
            required
          />
          <mat-error *ngIf="configValue.invalid && configValue.touched"
            >Value is required.</mat-error
          >
        </mat-form-field>
      </div>
      
    </div>
  </form>
  <div class="btn-container">
    <button
      mat-button
      class="geaui-button-primary actionbutton"
      (click)="data.flag === 'ConfigList'? sendListToParent(): sendDataToParent()"
      [disabled]="configForm.invalid"
    >
      {{action}}
    </button>
    <button
      mat-button
      class="geaui-button-secondary actionbutton"
      (click)="dialogRef.close(false)"
    >
      Cancel
    </button>
  </div>
</div>  
