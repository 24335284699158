<table class="TableHeader">
  <tr>
    <!-- <td>
      <mat-icon matSuffix class="TableHeaderIcon">factory</mat-icon>
    </td>
    <td>
      <span class="TableHeaderText">Sites</span>
    </td> -->
    <td >
      <mat-form-field  class="TableHeaderSearch">
        <mat-label style="color: var(--geaui-vibrant-blue);">Search Sites</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="siteTable.View.Search"
          (ngModelChange)="searchChange($event)"
        />
        <mat-icon style="color: var(--geaui-vibrant-blue);" matSuffix>search</mat-icon>
      </mat-form-field>
    </td>
    <!-- <td></td>
    <td></td> -->
  </tr>
</table>
<button class="hrt-export-btn"  (click)="exportTable()">
  <span class="csv-text">CSV</span>
  <mat-icon>download</mat-icon>
</button>
<table
  mat-table
  [dataSource]="siteTable.Result.Data"
  matSort
  matSortDisableClear
  [matSortActive]="siteTable.View.SortColumn"
  (matSortChange)="siteTable.RefreshData($event)"
>
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">{{ row.Name }}</td>
  </ng-container>
  <ng-container matColumnDef="Address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
    <td mat-cell *matCellDef="let row">{{ row.Address }}</td>
  </ng-container>
  <ng-container matColumnDef="City">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
    <td mat-cell *matCellDef="let row">{{ row.City }}</td>
  </ng-container>
  <ng-container matColumnDef="State">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
    <td mat-cell *matCellDef="let row">{{ row.State }}</td>
  </ng-container>
  <ng-container matColumnDef="Zip">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip</th>
    <td mat-cell *matCellDef="let row">{{ row.Zip }}</td>
  </ng-container>
  <ng-container matColumnDef="Country">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
    <td mat-cell *matCellDef="let row">{{ row.Country }}</td>
  </ng-container>
  <ng-container matColumnDef="AxAccountNumbers">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Numbers</th>
    <td mat-cell *matCellDef="let row">{{ row.AxAccountNumbers?.split(',').join(', ') }}</td>
  </ng-container>
  <ng-container matColumnDef="ProjectNumbers">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Numbers</th>
    <td mat-cell *matCellDef="let row">{{ row.ProjectNumbers?.split(',').join(', ') }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="siteColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: siteColumns"
    class="TableRowClickable"
    (click)="openSite(row.Id)"
  ></tr>
</table>
<mat-paginator
  [length]="siteTable.Result.Count"
  [pageSizeOptions]="siteTable.View.PageSizeOptions"
  [pageSize]="siteTable.View.PageSize"
  [pageIndex]="siteTable.View.Page"
  (page)="siteTable.RefreshData($event)"
>
</mat-paginator>
