<div mat-dialog-title class="title">
  <span class="title-text">Order Status Notes</span>
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
</div>
<div
  class="msg-container"
  [ngClass]="showMessage ? 'show' : ''"
  [style.background]="backgroundColor"
>
  {{ message }}
</div>
<div mat-dialog-content class="note-container">
  <div class="ship-shortage">
    <mat-checkbox
      color="primary"
      [(ngModel)]="shippingShortage"
      (change)="updateOrders(id, $event.checked)"
      class="shippingShortage"
      >Shipping Shortage</mat-checkbox
    >
  </div>
  <div class="comments-div">
    <hrt-comment
      [commentType]="commentType"
      [comments]="comments"
      (commentAdded)="addComment($event, id)"
    ></hrt-comment>
  </div>
</div>
