import { Component, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import {
  Applications,
  GetApplicationTypeText,
  GetAppList,
} from 'src/app/shared/data/application';
import { Companies, GetCompanyText } from 'src/app/shared/data/companies';
import {
  CommentView,
  Feedback,
  EnumDropdown,
  FeedbackAttachmentView,
  FeedbackCopy,
  FeedbackView,
} from 'src/app/shared/data/data.generated';
import {
  FeedbackPriorities,
  GetFeedbackPriorityText,
} from 'src/app/shared/data/feedback-priority';
import {
  FeedbackStatuses,
  GetFeedbackStatusText,
} from 'src/app/shared/data/feedback-status';
import {
  FeedbackTypes,
  GetFeedbackTypeText,
} from 'src/app/shared/data/feedback-type';
import { OmniVersions } from 'src/app/shared/data/omni-version';
import { ApiService } from 'src/app/shared/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationFeature } from 'src/app/shared/data/application-feature';
import { IDeactivateComponent } from 'src/app/candeactivate-guard.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { HrtBookmarkComponent } from 'src/app/shared/components/hrt-bookmark/hrt-bookmark.component';
import { TableView } from 'src/app/shared/services/api/table';
import { HrtDialogComponent } from 'src/app/shared/components/hrt-dialog/hrt-dialog.component';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments';
import { GetProductList, Products } from 'src/app/shared/data/feedback-product';
import { SmartLinkService } from 'src/app/shared/services/smart-link.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss'],
})
export class FeedbackDetailsComponent implements IDeactivateComponent {

  @ViewChild('feedbackForm') public feedbackForm: NgForm;

  id: number = 0;
  feedback: FeedbackView = new FeedbackView();
  createFeedback: Feedback = new Feedback();
  attachments: FeedbackAttachmentView[] = [];
  comments: CommentView[] = [];
  description: any; // change type.
  downloading: boolean = false;
  showFormattingOptions: boolean = false;
  canEdit: boolean = false;
  isMaxLengthExceeded = false;
  descriptionMaxLength: number = 3500;
  titleMaxLength: number = 350;
  mode: string = '';
  previewMode: boolean = true;
  currentDate = new Date();
  files: ExtendedFile[] = [];
  uploadedFiles: ExtendedFile[] = [];
  private initialState: FeedbackView = new FeedbackView();
  userType: any;
  link: any;
  url: any;
  bookmarkName: any;
  bookmarkType: string = 'Feedback';
  isBookmarked: boolean;
  listOptions: TableView = new TableView();
  disableFeature: boolean = false;
  isTitleMaxLengthExceeded: boolean = false;
  isDescriptionMaxLengthExceeded: boolean = false;
  linkCopied: boolean = false;
  commentType: string = 'Feedback';
  commentsData: CommentView[] = [];
  isInternal: boolean = false;
  isEnvironment : string | null = environment.name;
  jiraInputId: string = "";
  jiraIds: number[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur: boolean = true;

  companyDropdown: EnumDropdown[] = [];
  applicationDropdown: any[] = [];
  // productDropdown: EnumDropdown[] = [];
  priorityDropdown: EnumDropdown[] = [];
  statusDropdown: EnumDropdown[] = [];
  feedbackTypeDropdown: EnumDropdown[] = [];
  omniTypeDropdown: string[];

  getCompanyText = GetCompanyText;
  getFeedbackPriorityText = GetFeedbackPriorityText;
  getFeedbackStatusText = GetFeedbackStatusText;
  getFeedbackTypeText = GetFeedbackTypeText;
  getApplicationTypeText = GetApplicationTypeText;
  getProductList = GetProductList;
  getAppList = GetAppList;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private loader: LoaderService,
    private smartLinkService: SmartLinkService,
    private clipboard: Clipboard,
  ) {}

  ngOnInit(): void {
    document.querySelector('body')?.scrollTo(0, 0);
    this.route.params.subscribe((p: any) => {
      this.userType = sessionStorage.getItem('RoleId');
      this.isInternal = ['6000','7000'].includes(this.userType);
      this.disableFeature = !this.isInternal;
      this.id = Number.parseFloat(p['id']);
      this.canEdit = this.api.CanEditFeature(ApplicationFeature.Feedback);
      this.mode = p['mode'];
      this.previewMode = this.mode === 'edit';
      this.getDropdownValues();
      if (this.mode == 'add') {
        this.feedback.Created = this.currentDate.toJSON();
        this.feedback.LastModified = this.currentDate.toJSON();
        this.feedback.UserName = this.api.FullName;
      } else {
        this.refreshData();
      }
    });
    const userBookmarks = JSON.parse(
      sessionStorage.getItem('UserBookmarks') || ''
    );
    for (let i = 0; i < userBookmarks.length; i++) {
      if (
        userBookmarks[i].ForeignKey == this.id &&
        userBookmarks[i].BookmarkType == this.bookmarkType
      ) {
        this.isBookmarked = !this.isBookmarked;
      }
    }
  }

  setApplicationString(ids: number[]) {
    this.feedback.ApplicationIds = ids;
    this.feedback.ApplicationId = ids.join(",");
  }

  getAppIds(ids:string) {
    const apps = GetAppList(ids);
    const products = GetProductList(ids);
    return apps.concat(products).join(', ');
  }
  
  getProductGroups(applicationList: any, productList: any) {
    const productTypeGroup = [
      {
        name: 'Apps',
        dropdown: applicationList
      },
      {
        name: 'Products',
        dropdown: productList
      }
    ];
    return productTypeGroup;
  }

  togglePreviewMode() {
    if (this.mode === 'edit') {
      this.previewMode = !this.previewMode;
    }
  }

  refreshData() {
    if (!this.id) return;
    this.feedback = new FeedbackView();
    this.attachments = [];
    this.comments = [];
    this.loader.showLoadingIcon();
    this.api.Feedback.GetFeedbackView(this.id).subscribe({
      next: (x) => {
        this.feedback = x.Feedback;
        this.attachments = x.Attachments;
        this.files = this.convertAttachmentsToFiles(x.Attachments);
        this.comments = x.Comments;
        this.comments = this.comments.reverse();
        this.feedback.CompanyId = this.feedback.CompanyIds.split(',').map(x => parseInt(x));
        this.feedback.ApplicationIds = this.feedback.ApplicationId.split(',').map(x => parseInt(x));
        this.initialState = JSON.parse(JSON.stringify(x.Feedback));
        if(this.feedback.Source){ this.jiraIds = this.feedback.Source.split(',').map(x => parseInt(x)); }
        if (this.feedback.UserId == this.api.UserId) this.canEdit = true;
        this.loader.hideLoadingIcon();
        this.smartLinkService.updateTitle(this.feedback.Id, this.feedback.Title, this.bookmarkType);
      },
      error: (err) => {
        this.snackBar.open(
          'Failed to fetch feedback. Error: ' + err.messageStr,
          'X',
          {
            duration: 3000,
            panelClass: ['notify-error'],
            verticalPosition: 'top',
          }
        );
        this.loader.hideLoadingIcon();
      },
    });
  }

  deleteViewKeys(feedbackView: any): Feedback {
    let newFeedback = new FeedbackCopy();
    const feedbackKeys = Object.keys(newFeedback);
    Object.keys(feedbackView).map((key: string) => {
      if (feedbackKeys.includes(key)) {
        newFeedback[key] = feedbackView[key];
      }
    });
    return newFeedback;
  }

  private isFormDirty(): boolean {
    if (this.mode == 'add') return false;
    return JSON.stringify(this.initialState) !== JSON.stringify(this.feedback);
  }

  getDropdownValues() {
    const excludeCompanies = [
      'GEA Vancouver',
      'Bock',
      'GEA Suzhou',
      'GEA Cape Town',
    ];
    const excludePriority = ['N/A'];
    this.companyDropdown = Companies.filter(
      (company) => !excludeCompanies.includes(company.Name)
    );
    this.applicationDropdown = this.getProductGroups(Applications, Products);
    // this.productDropdown = Products;
    this.priorityDropdown = FeedbackPriorities.filter(
      (priority) => !excludePriority.includes(priority.Name)
    );
    this.statusDropdown = FeedbackStatuses;
    this.feedbackTypeDropdown = FeedbackTypes;
    this.omniTypeDropdown = OmniVersions;
  }

  checkMaxLength(event: any, maxCharacters: number, field: string): void {
    const value = event.target.value;
    if (value.length > maxCharacters) {
      event.target.value = value.substring(0, maxCharacters);
      if (field === 'title') {
        this.isTitleMaxLengthExceeded = true;
        this.isDescriptionMaxLengthExceeded = false; // description flag is not affected
      } else if (field === 'description') {
        this.isDescriptionMaxLengthExceeded = true;
        this.isTitleMaxLengthExceeded = false; // title flag is not affected
      }
    } else {
      if (field === 'title') {
        this.isTitleMaxLengthExceeded = false;
      } else if (field === 'description') {
        this.isDescriptionMaxLengthExceeded = false;
      }
    }
  }

  formatFileSize(sizeInBytes: number): string {
    const kilobytes = sizeInBytes / 1024;
    const megabytes = kilobytes / 1024;
    if (megabytes < 1) {
      return Math.round(kilobytes) + ' KB';
    } else {
      return megabytes.toFixed(2) + ' MB';
    }
  }

  formatDate(dateStr: string): string {
    if (!dateStr) return '';
    const utcDateString = dateStr.endsWith('Z') ? dateStr : dateStr + 'Z';
    const date = new Date(utcDateString);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  sanitizeDescription(htmlContent: string) {
    if (!htmlContent) return;
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

  getIcon(type: number) {
    if (type === 0) return 'insert_comment';
    if (type === 1) return 'library_add';
    if (type === 2) return 'bug_report';
    return '';
  }

  getIconStyle(type: number) {
    if (type === 0) return 'color: rgb(0, 0, 150);';
    if (type === 1) return 'color: rgb(0, 150, 0);';
    if (type === 2) return 'color: rgb(200, 0, 0);';
    return '';
  }

  addComment(newComment: string) {
    if (newComment == '') return;
    this.api.Feedback.AddFeedbackComment(
      this.id,
      newComment,
      this.commentType
    ).subscribe((x) => {
      this.refreshCommentData();
    });
  }

  refreshCommentData() {
    this.api.Feedback.GetFeedbackView(this.id).subscribe((x) => {
      this.comments = x.Comments;
      this.comments = this.comments.reverse();
    });
  }

  downloadAttachment(file: ExtendedFile) {
    if (this.downloading || !file.id) return;
    this.downloading = true;
    // Convert type File to type FeedbackAttachment
    const attachment: FeedbackAttachmentView = new FeedbackAttachmentView();
    attachment.FileName = file.name;
    attachment.Size = file.size;
    //@ts-ignore
    attachment.Id = file.id ? file.id : undefined;
    //@ts-ignore
    attachment.FeedbackId = file.feedbackId ? file.feedbackId : undefined;
    this.api.Feedback.DownloadFeedbackAttachment(attachment, (err: string) => {
      this.downloading = false;
      if (err) {
        //this.snackBar.open(err, undefined, {
        //    duration: 5000,
        //});
      }
    });
  }

  convertAttachmentsToFiles(
    attachments: FeedbackAttachmentView[]
  ): ExtendedFile[] {
    const files: any[] = [];
    if (attachments.length <= 0) return [];
    for (const attachment of attachments) {
      // Create a new File object
      const blob = new Blob([], { type: 'application/' }); // You can specify the file type
      const file = new File([blob], attachment.FileName, {
        type: 'application/',
      });

      // Set the file's size property
      Object.defineProperty(file, 'size', { value: attachment.Size });
      Object.defineProperty(file, 'id', { value: attachment.Id });
      Object.defineProperty(file, 'feedbackId', {
        value: attachment.FeedbackId,
      });
      Object.defineProperty(file, 'created', {
        value: attachment.Created,
      });
      Object.defineProperty(file, 'userName', {
        value: attachment.UserName,
      });

      files.push(file);
    }

    return files;
  }

  onFileDropped(files: FileList) {
    this.attachUniqueFile(files);
  }

  onFileSelected(event: any) {
    const incomingFiles: FileList = event.target.files;
    this.attachUniqueFile(incomingFiles);
  }

  attachUniqueFile(incomingFiles: FileList) {
    let fileListArr: ExtendedFile[] = Array.from(
      incomingFiles
    ) as ExtendedFile[];
    if (fileListArr.length < 0) return;

    let filesAdded = false;
    fileListArr.map((incomingFile) => {
      const isFilePresent = this.files.some(
        (file) => file.name === incomingFile.name
      );
      if (isFilePresent) {
        alert(`File ${incomingFile.name} is already present in attachments`);
      } else {
        if(incomingFile.size > 10 * 1024 * 1024) // file size should not exceed 10mb
        {
          alert(`File ${incomingFile.name} should not exceed 10mb`);
        } else {
          this.files.push(incomingFile);
          this.uploadedFiles.push(incomingFile);
          filesAdded = true;
        }
        
      }
    });
    if (this.previewMode && filesAdded) {
      this.saveFiles();
      this.clearFileInput();
    }
  }

  saveFiles() {
    if (this.uploadedFiles.length >= 0) {
      const feedbackObj = this.deleteViewKeys(this.feedback);
      this.api.Feedback.UpdateFeedback(
        feedbackObj,
        this.uploadedFiles
      ).subscribe({
        next: (res) => {
          this.uploadedFiles = [];
          this.api.Feedback.GetFeedbackView(this.id).subscribe((x) => {
            this.attachments = x.Attachments;
            this.files = this.convertAttachmentsToFiles(x.Attachments);
          });
        },
        error: (err) => {
          console.error('Failed to save files', err);
        },
      });
    }
  }
  clearFileInput() {
    // Get the file input element
    const inputElement = this.fileInput.nativeElement as HTMLInputElement;
    // Clear the selected file by setting the value to an empty string
    inputElement.value = '';
  }

  getNameById(items: Array<{ Id: number; Name: string }>, id: number): string {
    const item = items.find((item) => item.Id === id);
    return item ? item.Name : '';
  }

  deleteFile(index: number, file: ExtendedFile) {
    const dialogRef = this.dialog.open(HrtDialogComponent, {
      data: {title: "Delete Attachment", msg: "Are you sure want to permanently delete this file?"}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (file.id) {
          this.api.Feedback.DeleteFeedbackAttachment(file.id).subscribe((x) => {
            this.files.splice(index, 1);
            notify('Attachment Deleted', 'success', 2000);
          });
        } else {
          // Review this logic.
          const uploadIndex = this.files.length - 1 - (index + 1);
          this.uploadedFiles.splice(uploadIndex, 1);
          this.files.splice(index, 1);
        }
      }
    });
  }

  submit(): Promise<boolean> {
    console.log("Inside submit");
    return new Promise((resolve, reject) => {
      if (this.feedbackForm.invalid) {
        console.log('Form is invalid');
        resolve(false);
      }
      if (this.mode === 'edit') {
        if (this.previewMode) {
          this.togglePreviewMode();
        } else {
          this.loader.showLoadingIcon('Updating Feedback');
          const feedbackObj = this.deleteViewKeys(this.feedback);
          // console.log('Feedback obj sent:', feedbackObj);
          this.api.Feedback.UpdateFeedback(
            feedbackObj,
            this.uploadedFiles
          ).subscribe({
            next: (x) => {
              this.router.navigate(['/feedback', {}]);
              this.snackBar.open('Feedback updated successfully!', 'X', {
                duration: 3000,
                panelClass: ['notify-success'],
                verticalPosition: 'top',
              });
              this.initialState = JSON.parse(JSON.stringify(this.feedback));
              resolve(true);
              this.loader.hideLoadingIcon();
            },
            error: (err) => {
              this.loader.resetLoadingMessage();
              this.loader.hideLoadingIcon();
              this.snackBar.open(
                'Failed to update feedback. Error: ' + err,
                'X',
                {
                  duration: 3000,
                  panelClass: ['notify-error'],
                  verticalPosition: 'top',
                }
              );
              resolve(false);
            },
          });
        }
      } else {
        const feedbackObj = this.deleteViewKeys(this.feedback);
        this.loader.showLoadingIcon('Creating Feedback');
        this.api.Feedback.CreateFeedback(feedbackObj, this.files).subscribe({
          next: (x) => {
            this.router.navigate(['/feedback', {}]);
            this.snackBar.open('Feedback submitted successfully!', 'X', {
              duration: 3000,
              panelClass: ['notify-success'],
            });
            this.loader.hideLoadingIcon();
            resolve(true);
          },
          error: (err) => {
            this.snackBar.open(
              'Failed to submit feedback. Error: ' + err,
              'X',
              { duration: 3000, panelClass: ['notify-error'] }
            );
            this.loader.hideLoadingIcon();
            resolve(false);
          },
        });
      }
    });
  }

  goBack() {
    this.router.navigate(['/feedback']);
  }

  canExit(): Promise<boolean> {
    if (this.isFormDirty()) {
      if (!this.feedbackForm.valid) {
        alert('Some fields are missing or invalid');
        return Promise.resolve(false);
      } 
      const confirmation = window.confirm('Do you want to save changes?');
      if (confirmation) {
        return this.submit().then((result) => {
          return result;
        });
      } else {
        return Promise.resolve(true); // Resolve true to navigate away without saving
      }
    } else {
      return Promise.resolve(true);
    }
  }

  copyLink() {
    this.link = window.location.href;
    navigator.clipboard.writeText(this.link);
    //timer
    this.linkCopied = true;
    setTimeout(() => (this.linkCopied = false), 1500);
  }

  toggleBookmark() {
    if (!this.isBookmarked) {
      this.url = window.location.href;
      this.bookmarkName = this.feedback.Title;
      const dialogRef = this.dialog.open(HrtBookmarkComponent, {
        data: { url: this.url, bookmarkName: this.bookmarkName },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.bookmarkName = result;
        if (result) {
          this.api.Bookmark.AddBookmark(
            this.id,
            this.bookmarkType,
            this.url,
            this.bookmarkName
          ).subscribe({
            next: (x) => {
              this.isBookmarked = !this.isBookmarked;
            },
          });
          let existingBookmarks = JSON.parse(
            sessionStorage.getItem('UserBookmarks') || ''
          );
          existingBookmarks.push({
            ForeignKey: this.id,
            BookmarkType: this.bookmarkType,
          });
          sessionStorage.setItem(
            'UserBookmarks',
            JSON.stringify(existingBookmarks)
          );
        }
      });
    } else {
      this.api.Bookmark.DeleteBookmark(this.id, this.bookmarkType).subscribe({
        next: (x) => {
          this.isBookmarked = !this.isBookmarked;
        },
      });
      let existingBookmarks = JSON.parse(
        sessionStorage.getItem('UserBookmarks') || ''
      );
      existingBookmarks = existingBookmarks.filter(
        (bookmark: { ForeignKey: number; BookmarkType: string }) =>
          !(
            bookmark.ForeignKey === this.id &&
            bookmark.BookmarkType === this.bookmarkType
          )
      );
      sessionStorage.setItem(
        'UserBookmarks',
        JSON.stringify(existingBookmarks)
      );
    }
  }

  addJiraChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    //add only if id is number
    if (value && !isNaN(Number(value)) ) {
      this.jiraIds.push(parseInt(value));
      this.feedback.Source = this.jiraIds.join(",");
    }
    
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeChip(jiraId: number): void {
    const index = this.jiraIds.indexOf(jiraId);
    if (index >= 0) {
        this.jiraIds.splice(index, 1);
        this.feedback.Source = this.jiraIds.join(',');
    }
  }

  goToJira(jiraId: any){
    window.open("https://geagroup.atlassian.net/jira/software/c/projects/HRTC/issues/HRTC-"+jiraId);
  }

  getInfoText(){
    return `
    Low: Small quality of life improvements, little to no impact on user experience and functionality. General feedback.\n
    Medium: Bigger quality of life improvements or feature requests with meaningful impact once implemented. Bugs with little to no impact on user experience and functionality.\n
    High: Bugs or features with strong impact on functionality and user experience.\n
    Urgent: Bugs that, if unresolved, will result in significant financial repercussions.`;
  }

  cancel() {
    this.router.navigate(['/feedback']);
  }
}

type ExtendedFile = File & {
  id: number;
  feedbackId: number;
  created: string;
  userName: string;
};
