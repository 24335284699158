import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CommentView, OrderStatusItem } from '../../shared/data/data.generated';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
  GetCustomTypeText,
} from 'src/app/shared/data/product-types';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { Router } from '@angular/router';
// import notify from 'devextreme/ui/notify';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { LoaderService } from 'src/app/shared/services/loader.service';
import {
  GetFilterConfigKey,
  GetFilterConfigType,
} from 'src/app/shared/data/filterConfigurationTypes';
import { debounceTime, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { OrderStatusNoteComponent } from 'src/app/shared/components/order-status-notes/order-status-note/order-status-note.component';

@Component({
  templateUrl: 'order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class OrderStatusComponent implements OnInit {
  constructor(
    private readonly _api: ApiService,
    private _router: Router,
    public loader: LoaderService,
    public dialog: MatDialog,
  ) {}

  id: number = 0;
  filterDelayed = <any>undefined;
  filter = '';
  repBox: string = '';
  year: Date | undefined = undefined;
  salesManagerBox: string = '';
  projectManagerBox: string = '';
  plannerBox: string = '';
  detailedFilter = '';
  items = <any>[];
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  noResults = false;
  showNotes = false;
  detailedFilterText = '';
  notesReferenceId = -1;
  orderStatusTable: Table<OrderStatusItem> = new Table<OrderStatusItem>(
    this.loader,
  );
  tableId: string = 'orderStatus';
  searchPlaceholder = 'Search Orders';
  expandedElement: any;
  commentType: string = 'Orders';
  comments: CommentView[] = [];
  shippingShortage: boolean = false;

  displayedColumns: string[] = [
    'Project Name',
    'End User',
    'Address',
    'Contract Val',
    'Customer',
    'PO Number',
    'Payment Terms',
    'Notes',
  ];

  shipmentDate = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  turnInDate = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  getCustomTypeText = GetCustomTypeText;
  getCustomTypeIcon = GetCustomTypeIcon;
  getCustomTypeIconWidth = GetCustomTypeIconWidth;

  ngOnInit(): void {
    // set Initial table filters.
    this.orderStatusTable.View.SortColumn = 'TurnInDate';
    this.orderStatusTable.View.SortDesc = 'True';
    this.orderStatusTable.View.HasPaging = false;
    this.refreshData();
    this.orderStatusTable.inputSubject
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.orderStatusTable.SearchUpdated();
      });
  }

  clear() {
    this.repBox = '';
    this.year = undefined;
    this.turnInDate.value.start = null;
    this.shipmentDate.value.start = null;
    this.turnInDate.value.end = null;
    this.shipmentDate.value.end = null;
    this.salesManagerBox = '';
    this.projectManagerBox = '';
    this.plannerBox = '';
    this.filterData();
  }

  filterData() {
    this.detailedFilter =
      'Filter=1' +
      (this.repBox ? ',Rep=' + this.repBox : '') +
      (this.year ? ',Year=' + this.year.getFullYear() : '') +
      (this.turnInDate.value.start
        ? ',TurnInDate=' +
          this.turnInDate.value.start?.toJSON() +
          '|' +
          this.turnInDate.value.end?.toJSON()
        : '') +
      (this.shipmentDate.value.start
        ? ',ShipDate=' +
          this.shipmentDate.value.start?.toJSON() +
          '|' +
          this.shipmentDate.value.end?.toJSON()
        : '') +
      (this.salesManagerBox ? ',SalesManager=' + this.salesManagerBox : '') +
      (this.projectManagerBox
        ? ',ProjectManager=' + this.projectManagerBox
        : '') +
      (this.plannerBox ? ',Planner=' + this.plannerBox : '');

    this.refreshData();
  }

  searchChange(searchText: string) {
    if (
      searchText.trim() !== this.orderStatusTable.inputSubject.getValue() ||
      searchText === ''
    ) {
      this.orderStatusTable.inputSubject.next(searchText);
    }
  }

  applyFilters(filterEvent: any) {
    this.orderStatusTable.View.Filters = {};
    Object.keys(filterEvent).map((key: string) => {
      const filterKey = GetFilterConfigKey(key);
      const values = filterEvent[key];
      const filterType = GetFilterConfigType(key);
      if (values.length <= 0) return;
      // Convert from Type - selectedFilters to Table Filters
      this.orderStatusTable.addToFilter(filterKey, values, key, filterType);
    });
    this.orderStatusTable.SearchUpdated();
  }

  refreshData(reloadPage?: boolean, reloadMsg?: string) {
    this.items = undefined;
    this.noResults = false;
    this.loader.showLoadingIcon(reloadMsg ?? '');
    this.orderStatusTable.GetApi = (listOptions: TableView) => {
      return this._api.Projects.GetOrderStatusItems(listOptions).pipe(
        tap((returnItems: any) => {
          if (returnItems) {
            for (const item of returnItems.Data) {
              item.TurnInDate = this.formatDate(item.TurnInDate);
              item.RequestedShipDate = this.formatDate(item.RequestedShipDate);
              item.CurrentShipDate = this.formatDate(item.CurrentShipDate);
              item.ApprovalDrawingDate = this.formatDate(
                item.ApprovalDrawingDate,
              );
              item.AcknowledgedShipDate = this.formatDate(
                item.AcknowledgedShipDate,
              );
            }

            let finalList: any[] = [];
            let salesReps: string[] = [];
            let notes: any[] = [];

            for (let i = 0; i < returnItems.Comments.length; i++) {
              let commentsRow: any = returnItems.Comments[i];
              notes.push(commentsRow);
            }

            for (let i = 0; i < returnItems.Data.length; i++) {
              let itemRow: any = returnItems.Data[i];
              if (salesReps.indexOf(itemRow.SalesRep) === -1) {
                salesReps.push(itemRow.SalesRep);
                finalList.push({
                  SalesRep: itemRow.SalesRep,
                  ProjectNames: [],
                  Projects: [],
                });
              }
              let repIndex = salesReps.indexOf(itemRow.SalesRep);
              let repRow = finalList[repIndex];
              if (repRow.ProjectNames.indexOf(itemRow.Project) === -1) {
                repRow.ProjectNames.push(itemRow.Project);
                repRow.Projects.push({
                  ProjectName: itemRow.Project,
                  FirstItem: itemRow,
                  LineItems: [],
                  ProjectNotes: [],
                });
              }
              let projectIndex = repRow.ProjectNames.indexOf(itemRow.Project);
              let projectRow = repRow.Projects[projectIndex];
              projectRow.LineItems.push(itemRow);
            }

            for (let i = 0; i < finalList.length; i++) {
              let finalListRow: any = finalList[i];
              for (let j = 0; j < finalListRow.Projects.length; j++) {
                let projectsRow: any = finalListRow.Projects[j].FirstItem;
                for (let k = 0; k < notes.length; k++) {
                  let notesRow: any = notes[k];
                  if (projectsRow.Id === notesRow.ForeignKey) {
                    finalList[i].Projects[j].ProjectNotes.push(notes[k]);
                  }
                }
              }
            }
            this.items = finalList;
            this.noResults = this.items.length === 0;
          }
          this.loader.hideLoadingIcon();
        }),
      );
    };
    if (reloadPage) {
      this.orderStatusTable.refreshDataForTable()?.subscribe((val) => {});
    }
  }

  formatDate(dateStr: string): string {
    if (!dateStr) return '';
    const utcDateString = dateStr.endsWith('Z') ? dateStr : dateStr + 'Z';
    const date = new Date(utcDateString);
    if (date.getFullYear() < 1980 || !date.valueOf()) return 'N/A';
    return date.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  }

  onNotesClick(notesId: number, shippingShortage: boolean, projectNotes: any, event: MouseEvent) {
    event.stopPropagation();
    this.showNotes = !this.showNotes;
    this.comments = projectNotes.reverse();
    this.id = notesId;
    this.shippingShortage = shippingShortage;

    const dialogRef = this.dialog.open(OrderStatusNoteComponent, {
      data: {
        id: this.id,
        comments: this.comments,
        shippingShortage: this.shippingShortage,
        commentType: this.commentType,
      },
      width: '55%',
    });

    dialogRef.afterClosed().subscribe((noteData: any) => {
      if (noteData.dataChanged) {
        this.refreshData(true, 'Refreshing orders');
      }
    });
  }

  dateDiffStyle(dateStr1: string, dateStr2: string) {
    if (dateStr1 == 'N/A' || dateStr2 == 'N/A') return '';
    let date1 = new Date(dateStr1);
    let date2 = new Date(dateStr2);
    if (date1.getFullYear() < 1950 || date2.getFullYear() < 1950) return '';
    if (date1.getTime() > date2.getTime())
      return 'color: red; font-weight: bold;';
    else if (date1.getTime() < date2.getTime())
      return 'color: blue; font-weight: bold;';
    return '';
  }

  formatRep(str: string) {
    if (!str || str.trim() == '') return 'None';
    let match = str.match(/\d+.*/);
    return match === null ? 'None' : match[0];
  }

  formatToEmail(str: string) {
    if (!str || str.trim() == '') return 'None';
    var cleanStr = str.replace(/,|\s+/g, ' ').trim().toUpperCase();
    var email = cleanStr.replace(' ', '.') + '@gea.com';
    return email;
  }

  getShipmentLink(str: string) {
    if (!str || str.trim() == '') return 'None';
    if (str.match(/[xX][pP][oO].*\d+/))
      return (
        "<a href='https://track.xpoweb.com/ltl-shipment/" +
        str.trim().split(' ').reverse()[0] +
        "/' target='_blank'>" +
        str +
        '</a>'
      );
    if (str.match(/[fF][eE][dD][ ]*[eE][xX].*\d+/))
      return (
        "<a href='https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=" +
        str.trim().split(' ').reverse()[0] +
        "' target='_blank'>" +
        str +
        '</a>'
      );
    return str;
  }

  formatCurrency(currency: number) {
    return this.formatter.format(currency);
  }

  checkIfProjectSiteExists(projectName: string) {
    // extract first 5 digits as location.
    const projectId = projectName.split('-')[0];
    this._api.Sites.GetOrderSiteInformation(projectId).subscribe((x) => {
      if (x) {
        this._router.navigate(['/site-details', { id: x.Id }]);
      } else {
        alert('No site exists for this order');
      }
    });
  }

  exportTable() {
    const listOptions = this.orderStatusTable.View;
    listOptions.Page = 0;
    listOptions.HasPaging = false;
    this._api.Projects.ExportOrderStatusItems(listOptions, 'Order Status');
  }
}
