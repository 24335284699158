import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Dropdown } from '../../data/filterConfigurationTypes';

@Component({
  selector: 'gea-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrl: './dropdown-button.component.scss'
})
export class DropdownButtonComponent {

    @Input() dropdownLabel: string;
    @Input() dropdownOptions: Dropdown[];
    @Output() optionClicked = new EventEmitter<Dropdown>();
    showDropdown = false;


    constructor(private el: ElementRef) {}

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
      // Check if the target element is outside the component's element
      if (!this.el.nativeElement.contains(event.target)) {
        this.showDropdown = false;
      }
    }

    ngOnInit() {
      // this.dropdownOptions = [
      //   {
      //     key: 'csv',
      //     value: 'CSV'
      //   },
      //   {
      //     key: 'excel',
      //     value: 'Excel'
      //   }
      // ]
    }

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    }

    emitOption(option: Dropdown) {
      this.optionClicked.emit(option);
    }
}