import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommentView } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-order-status-note',
  templateUrl: './order-status-note.component.html',
  styleUrl: './order-status-note.component.scss',
})
export class OrderStatusNoteComponent {
  shippingShortage: any;
  id: number;
  commentType: string;
  comments: CommentView[] = [];
  message = '';
  showMessage = false;
  backgroundColor = 'orange';
  dataChanged = false;

  private _snackBar = inject(MatSnackBar);

  constructor(
    public dialogRef: MatDialogRef<OrderStatusNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _api: ApiService,
  ) {}

  ngOnInit() {
    if (this.data) {
      this.shippingShortage = this.data.shippingShortage;
      this.id = this.data.id;
      this.commentType = this.data.commentType;
      this.comments = this.data.comments;
    }
  }

  updateOrders(id: number, checked: boolean) {
    this.message = 'Updating shipping status...';
    this.showMessage = true;
    this._api.Projects.UpdateOrderStatus(id, this.shippingShortage).subscribe({
      next: (res) => {
        this.shippingShortage = checked;
        this.backgroundColor = 'green';
        this.message = 'Shipping status updated';
      },
      error: (err) => {
        this.backgroundColor = 'red';
        this.message = 'Failed to update shipping status';
        console.error('Failed to update orders', err);
      },
      complete: () => {
        this.dataChanged = true;
        setTimeout(() => {
          this.showMessage = false;
          this.backgroundColor = 'orange';
        }, 1000);
      }
    });
  }

  addComment(newComment: string, id: number) {
    if (newComment == '') return;
    this.message = 'Adding comment...';
    this.showMessage = true;
    this._api.Feedback.AddFeedbackComment(
      id,
      newComment,
      this.commentType,
    ).subscribe({
      next: (comment) => {
        comment.UserName = this._api.FullName;
        this.comments.unshift(comment);
        this.backgroundColor = 'green';
        this.message = 'Comment added';
      },
      error: (err) => {
        this.backgroundColor = 'red';
        this.message = 'Failed to add comment';
        console.error('Failed to add comment', err);
      },
      complete: () => {
        this.dataChanged = true;
        setTimeout(() => {
          this.showMessage = false;
          this.backgroundColor = 'orange';
        }, 1000);
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  closeDialog() {
    this.dialogRef.close({dataChanged: this.dataChanged});
  }

}
