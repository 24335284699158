import { Component, Input } from '@angular/core';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { debounceTime } from 'rxjs';
import { GeoChartOptions } from 'src/app/shared/components/geo-chart/geo-chart.component';
import { Asset } from 'src/app/shared/data/data.generated';
import {
  HRTColumnDefinition,
  SelectedFilters,
} from 'src/app/shared/data/filterConfigurationTypes';
import { GetCustomTypeText } from 'src/app/shared/data/product-types';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  Table,
  TableView,
} from 'src/app/shared/services/api/table';
import {
  DashboardQueryType,
  DashboardService,
  DashboardServiceCondition,
} from 'src/app/shared/services/dashboard.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { RegionDataService } from 'src/app/shared/services/region-data.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'app-dashboard-iot',
  templateUrl: './dashboard-iot.component.html',
  styleUrls: ['./dashboard-iot.component.scss'],
})
export class DashboardIotComponent {
  tableId: string = 'dashboardIot';
  totalCount: number = 0;
  columnDefinitions: HRTColumnDefinition[];
  tableData: any[];
  listOptions: TableView = new TableView();
  unsortableKeys = ['ProductTypeId', 'ProductTypeIdText'];

  countries: any[] = [];
  presetFilter: SelectedFilters = {
    Country: [
      {
        key: 'USA',
        label: 'United States',
      },
    ],
  };
  defaultTableConfig: FilterTableSettings = {
    pageSize: 20,
    columns: {},
  };
  assetsType: DashboardQueryType = DashboardQueryType.Assets;
  selectedCountry: string = 'USA';
  selectedState: string = '';
  selectedType: DashboardQueryType = DashboardQueryType.Assets;
  selectedCondition: DashboardServiceCondition = DashboardServiceCondition.All;
  selectedProductType: number = -1;
  fetchConnectedAssets: boolean = true;
  appliedType: DashboardQueryType = DashboardQueryType.Assets;

  showWorldMap: boolean = true;
  showStateMap: boolean = true;
  tableDataLoading: boolean = false;
  initialLoad: boolean = true;

  mapData: (string | number)[][] = [];
  mapDataLoading: boolean = false;
  worldOptions: GeoChartOptions = GeoChartOptions.WorldViewOptions;
  stateOptions: GeoChartOptions = GeoChartOptions.StateViewOptions;

  normalCount = 0;
  normalPercent = 0;

  warningCount = 0;
  warningPercent = 0;

  shutdownCount = 0;
  shutdownPercent = 0;

  getCustomTypeText = GetCustomTypeText;

  assetTable: Table<Asset> = new Table<Asset>(this.loader);

  public displayedColumns: string[] = [
    'Icon',
    'AssetTypeId',
    'GeaReferenceName',
    'Manufacturer',
    'Model',
    'AlternativeModel',
    'SerialNumber',
    'SalesOrderNumber',
    'Annunciation',
    'MotorStatus'
  ];

  constructor(
    private regionData: RegionDataService,
    private api: ApiService,
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
    private websocketService: WebsocketService,
    private loader: LoaderService
  ) {}

  @Input() data: any;

  ngOnInit(): void {
    this.websocketService.liveData.subscribe((data) => {
      this.updateCounts();
    });

    this.applyPresetFilters();

    this.assetTable.GetApi = (listOptions: TableView) => {
      this.listOptions = listOptions;
      return this.api.Assets.GetAssets(
        listOptions,
        undefined,
        this.selectedCountry,
        this.selectedState,
        this.selectedProductType,
        this.fetchConnectedAssets
      );
    };

    // this.feedbackTable.RefreshData();
    this.assetTable.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.assetTable.SearchUpdated();
    });

    if (sessionStorage.getItem(this.tableId + '-listOptions')) {
      this.listOptions = JSON.parse(
        sessionStorage.getItem(this.tableId + '-listOptions') || ''
      );
    }

    this.assetTable.Updated = () => (this.tableDataLoading = false);

    // For initial load, control doesn't go in tap unless subscribed.
    // this.assetTable.refreshDataForTable()?.subscribe();

    // this.assetTable.Updated = () => (this.tableDataLoading = false);
    // this.assignMockColDefinitions();
    this.updateMapAndTable();
    this.utilityService.setActiveTab(3, 'dashboardActiveIndex');
  }

  ngAfterViewInit() {
    this.initialLoad = false;
  }

  updateCounts() {
    this.normalCount =
      Object.values(this.websocketService.annunStatuses).filter(
        (x: any) => x.annun_status == 0
      )?.length ?? 0;
    this.normalPercent =
      (this.normalCount / this.websocketService.connectedAssets.length) * 100;

    this.warningCount =
      Object.values(this.websocketService.annunStatuses).filter(
        (x: any) => x.annun_status == 1
      )?.length ?? 0;
    this.warningPercent =
      (this.warningCount / this.websocketService.connectedAssets.length) * 100;

    this.shutdownCount =
      Object.values(this.websocketService.annunStatuses).filter(
        (x: any) => x.annun_status == 2
      )?.length ?? 0;
    this.shutdownPercent =
      (this.shutdownCount / this.websocketService.connectedAssets.length) * 100;
  }

  applyPresetFilters() {
    if (!sessionStorage.getItem(this.tableId)) return;
    this.presetFilter = JSON.parse(sessionStorage.getItem(this.tableId) || '');

    this.selectedCountry =
      this.presetFilter['Country'] && this.presetFilter['Country'].length > 0
        ? this.presetFilter['Country'][0].key.toString()
        : '';
    this.selectedState =
      this.presetFilter['State'] && this.presetFilter['State'].length > 0
        ? this.presetFilter['State'][0].key.toString()
        : '';
  }


  handleSelectedFilters(selectedFilters: SelectedFilters) {
    // console.log('Selected filters in parent:', selectedFilters);
    if (selectedFilters) {
      if (!selectedFilters['Country']) {
        this.selectedCountry = '';
        this.selectedState = '';
      }
      if (!selectedFilters['State']) {
        this.selectedState = '';
      }
      this.updateMapAndTable();
    }
  }

  regionSelected(region: string) {
    if (this.showWorldMap) {
      let lookup2Digit = this.regionData.Countries.find(
        (x) => x.Code2 == region
      );
      let lookupName = this.regionData.Countries.find((x) => x.Name == region);
      lookup2Digit?.Name
        ? this.updateCountryFilter(lookup2Digit.Code3, lookup2Digit.Name)
        : null;
      this.selectedCountry = lookup2Digit?.Code3 ?? lookupName?.Code3 ?? region;
      this.updateMapAndTable();
    } else if (this.showStateMap) {
      const stateName = this.regionData.States.find(
        (x) => x.Code == region
      )?.Name;
      this.selectedState = region;
      if (stateName) this.updateStateFilter(region, stateName);
      this.updateMapAndTable();
    }
  }

  updateCountryFilter(countryCode: string, countryName: string) {
    if (this.presetFilter) {
      this.presetFilter = {
        ...this.presetFilter,
        Country: [
          {
            key: countryCode,
            label: countryName,
          },
        ],
      };
    }
  }

  updateStateFilter(stateCode: string, stateName: string) {
    if (this.presetFilter) {
      this.presetFilter = {
        ...this.presetFilter,
        State: [
          {
            key: stateCode,
            label: stateName,
          },
        ],
      };
    }
  }

  updateMapAndTable() {
    //this.tableDataLoading = true;
    this.stateOptions.colorAxis = { colors: ['lightGray', '#107c3c'] };

    if (this.selectedType !== DashboardQueryType.OperatingData) {
      this.appliedType = this.selectedType;
    }
    if (this.selectedCountry === '' && this.selectedState === '') {
      this.showWorldMap = true;
      this.showStateMap = false;
    } else if (this.selectedCountry === 'USA') {
      //this.selectedState === ""
      this.showWorldMap = false;
      this.showStateMap = true;
    } else {
      this.showWorldMap = false;
      this.showStateMap = false;
    }
    if (this.showWorldMap || this.showStateMap) {
      this.mapDataLoading = true;
      this.assetTable.oldRequest = {};
      this.assetTable.SearchUpdated();
      this.api.Assets.GetAssetMapData(
        this.selectedType,
        this.selectedCountry,
        this.selectedProductType,
        this.selectedCondition,
        this.fetchConnectedAssets
      ).subscribe((x) => {
        this.mapData = this.dashboardService.normalizeCountryData(x);
        this.mapDataLoading = false;
      });
    } else {
      this.assetTable.oldRequest = {};
      this.assetTable.SearchUpdated();
    }
  }


  rowClicked(rowData: any) {
    // console.log('Row click event', rowData);
    this.utilityService.openAsset(rowData.Id);
  }

  exportIotAssets() {
    const listOptions = this.listOptions;
    listOptions.Page = 0;
    listOptions.HasPaging = false;
    this.api.Assets.ExportAssets(
      listOptions,
      undefined,
      this.selectedCountry,
      this.selectedState,
      this.selectedProductType,
      this.fetchConnectedAssets,
      'IOT'
    );
  }

  backToWorld() {
    this.selectedCountry = '';
    this.selectedState = '';
    // sets it as [] and not undefined to handle previous selected filter in hrt-table.ts (set)
    this.presetFilter = {
      ...this.presetFilter,
      Country: [],
      State: [],
    };
    this.updateMapAndTable();
  }


  ngOnDestroy() {
    sessionStorage.setItem(
      this.tableId + '-listOptions',
      JSON.stringify(this.listOptions)
    );
  }
}
