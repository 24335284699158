import { ColumnDefinition } from '@gea/digital-ui-lib';
import { FilterConfig } from './filterConfigurations';

export interface Dropdown {
  key: string | number | boolean;
  label: string;
}

export interface GroupDropdown {
  name: string;
  options: Dropdown[];
}


export class StoredFilter {}

export class StoredSingleFilter {
  [key: string]: Dropdown;
}

export class StoredMultipleFilter {
  [key: string]: Dropdown[];
}

export interface FilterMetadata {
  [key: string]: FilterOptions;
}

export class SubFilterMetadata {
  [key: string]: string[];
}

export interface AppliedFilters {
  configName: string,
  key: string,
  name: string,
  value: string
}[];

export interface SelectedFilters {
  [filterConfigName: string]: Dropdown[]
}

export type FilterRenderConfiguration = FilterOptions & {
  configName: string;
};

export interface BaseFilterOptions {
  key: string,
  name: string,
  options?: Dropdown[],
  internalOnly?: boolean; // only seen by internal users.
}

export interface GroupFilterOptions {
  key: string,
  name: string,
  groupedOptions: GroupDropdown[],
  internalOnly?: boolean; // only seen by internal users.
}

interface ClientSideFilterOptions extends BaseFilterOptions {
  isClientSide: true;
  clientFilterFunction: (field: string, param: any) => void;
}

interface NonClientSideFilterOptions extends BaseFilterOptions {
  isClientSide?: false;
}

export interface SelectFilterOptions extends BaseFilterOptions {
  type: 'select' | 'multiSelect';
}

export interface GroupedSelectFilterOptions extends GroupFilterOptions {
  type: 'groupedSelect' | 'groupedMultiSelect';
  placeholder?: string
}

export interface AutoCompleteFilterOptions extends BaseFilterOptions {
  type: 'autoSelect' | 'autoMultiSelect';
  placeholder?: string;
  prefetch: boolean;
}

export interface DateRangeFilterOptions extends BaseFilterOptions {
  type: 'dateRange',
  placeholder?: string;
  dateFormat: string;
}

export type FilterOptions = (SelectFilterOptions | AutoCompleteFilterOptions | DateRangeFilterOptions | GroupedSelectFilterOptions ) & (ClientSideFilterOptions | NonClientSideFilterOptions);

export type HRTColumnDefinition = ColumnDefinition & {
  hasFilter?: boolean;
};


export function GetFilterConfigKey(config: string) {
  return FilterConfig[config]?.key;
}

export function GetFilterConfigName(config: string) {
  return FilterConfig[config]?.name;
}

export function GetFilterConfigType(config: string) {
  return FilterConfig[config]?.type;
}

export function IsClientSideFilter(config: string) {
  return FilterConfig[config]?.isClientSide;
}

export function isClientSideFilterOptions(obj: any): obj is ClientSideFilterOptions {
  return obj.isClientSide === true && typeof obj.clientFilterFunction === 'function';
}

export function GetClientFilterFunc(config: string) {
  const filterOption = FilterConfig[config];
  if (isClientSideFilterOptions(filterOption)) {
    return filterOption.clientFilterFunction;
  }
  return undefined; // or handle the case where it's not a ClientSideFilterOptions
}


